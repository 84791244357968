import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import { HeaderTags } from './components/HeaderTags/HeaderTags';
import LandingClient from './layouts/LandingClient/LandingClient';
import LandingTherapist from './layouts/LandingTherapist/LandingTherapist';
import Navbar from './layouts/Navbar/Navbar';
import LoadTags from './containers/LoadTags/LoadTags';
import LoadProfileUser from './containers/LoadProfileUser/LoadProfileUser';

import State from './layouts/State/State';
import SearchPost from './layouts/SearchPost/SearchPost';
import Quiz from './components/Quiz/Quiz';

import Login from './layouts/Login/Login';
import SignUpTherapist from './layouts/SignUpTherapist/SignUpTherapist';

import ResetPassword from './layouts/ResetPassword/ResetPassword';
import VerifyEmail from './layouts/VerifyEmail/VerifyEmail';
import PageScrollToTop from './hooks/ScrollToTop';
import Footer from './components/Footer/Footer';
import Tracker from './hooks/Tracker';

import NotFound from './layouts/NotFound/NotFound';
import LegalTerms from './layouts/LegalTerms/LegalTerms';
import LegalPrivacy from './layouts/LegalPrivacy/LegalPrivacy';
import LegalGuidelines from './layouts/LegalGuidelines/LegalGuidelines';

import { getStateUrl, allStates, quizzes } from './helpers';
import _ from '@/lodash';

function routeStates() {
  return _.map(allStates, (state) => (
    <Route
      path={`${getStateUrl(state)}/:tag?`}
      key={state}
      element={
        <>
          <State state={state} />
          <LoadTags />
        </>
      }
    />
  ));
}

function routeQuizzes() {
  return _.map(quizzes, (quiz) => (
    <Route path={quiz.url} key={quiz.url} element={<Quiz quiz={quiz} />} />
  ));
}

function App() {
  return (
    <Router>
      <Navbar />

      <HeaderTags
        title="MintLeaf | Find a Therapist, Psychologist, Counselor"
        description="Find the right therapist for you. See how each therapist talks about their approach to mental health."
        url="https://mintleaf.co"
      />

      <div className="page-container">
        <Routes>
          <Route path="/" element={<LandingClient />} />

          <Route
            path="/therapist/:short_id"
            element={
              <>
                <LoadProfileUser />
                <LoadTags />
              </>
            }
          />

          <Route
            path="/therapist/:url_tag/:short_id"
            element={
              <>
                <LoadProfileUser />
                <LoadTags />
              </>
            }
          />

          {routeStates()}

          <Route path="/therapists/:tag?" element={<LandingClient />} />
          <Route path="/for-providers" element={<LandingTherapist />} />
          <Route path="/register" element={<SignUpTherapist />} />
          <Route path="/login" element={<Login />} />

          <Route
            path="/posts/:slug"
            element={
              <>
                <SearchPost />
                <LoadTags />
              </>
            }
          />

          {/* {routeQuizzes()} */}

          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/verify" element={<VerifyEmail />} />

          {/* <Route path="/billing">
              <Billing />
            </Route> */}

          <Route path="/legal/privacy-policy" element={<LegalPrivacy />} />
          <Route path="/legal/terms-of-use" element={<LegalTerms />} />
          <Route path="/legal/user-guidelines" element={<LegalGuidelines />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </div>

      <Tracker />
      <PageScrollToTop />
      <Footer />
    </Router>
  );
}

export default App;

import _ from '@/lodash';
import { createReducer } from '@reduxjs/toolkit';
import {
  loginAction,
  logoutAction,
  loadDocsAction,
  LoginPayload,
} from './actions';
import { getSafeStorage } from './store';
import { differenceInDays } from 'date-fns';

interface CollectionsStore {
  [collection: string]: { [docId: string]: any };
}

const safeStorage = getSafeStorage();

// @deprecated token is from old auth, clear storage so user is logged out
if (safeStorage.getItem('token')) {
  safeStorage.clear();
}

const lastSeen = safeStorage.getItem('last_seen');
const lastSeenDate = lastSeen ? new Date(lastSeen) : new Date();
const lastSeenDays = differenceInDays(new Date(), lastSeenDate);
const isEstimatedExpired = lastSeenDays > 28;

if (isEstimatedExpired) {
  safeStorage.clear();
}

export const loginReducer = createReducer<LoginPayload>(
  {
    id: safeStorage.getItem('id'),
    short_id: safeStorage.getItem('short_id'),
    url_tag: safeStorage.getItem('url_tag'),
  },
  (builder) => {
    builder
      .addCase(loginAction, (state, action) => {
        const { id, short_id, url_tag } = action.payload;

        state.id = id;
        state.short_id = short_id;
        state.url_tag = url_tag;
      })
      .addCase(logoutAction, (state) => {
        state.id = null;
        state.short_id = null;
        state.url_tag = null;
      });
  },
);

export const collectionsReducer = createReducer<CollectionsStore>(
  {},
  (builder) => {
    builder.addCase(loadDocsAction, (state, action) => {
      const { docs, collection } = action.payload;

      _.each(docs, (doc) => {
        if (collection === 'user') {
          state[collection] = {
            ...state[collection],
            [doc.id]: doc,
            [doc.short_id]: doc,
          };
        } else {
          state[collection] = { ...state[collection], [doc.id]: doc };
        }
      });
    });
  },
);
